import {NgModule} from "@angular/core";
import {LocalCurrencyPipe} from "@app/shared/pipes/local-currency/local-currency.pipe";
import {CommonModule} from "@angular/common";
import {FindUserPipe} from "@app/shared/pipes/find-user/find-user.pipe";

@NgModule({
  declarations: [FindUserPipe],
  imports: [CommonModule],
  exports: [FindUserPipe],
})
export class FindUserModule {}
