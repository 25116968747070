import { Inject, Injectable, Injector } from '@angular/core';
import { DialogBoxComponent } from '@app/shared/components/dialog-box/dialog-box.component';
import { ELogLevel } from '@app/shared/components/log-content/enums/log-level.enum';
import { LogContentService } from '@app/shared/components/log-content/services/log-content.service';
import { PinCodeComponent } from '@app/shared/modals/pincode/pincode.component';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { defaultIfEmpty, lastValueFrom } from 'rxjs';
import {
  RefundDialogComponent,
  RefundIntents,
} from '@app/modules/portal/pages/economy/pages/sales/components/refund-dialog/refund-dialog.component';
import { ISaleModel } from '@models/sale.model';
import { IPaymentIntentModel } from '@models/payment-intent.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmMoveDialogComponent } from '@app/modules/portal/pages/bookings/components/confirm-move-dialog/confirm-move-dialog.component';
import { IUserModel } from '@models/user.model';
import { ICalendarBookingModel } from '@models/calendar-booking.model';

export type DialogCompletionData = {
  role: 'confirm' | 'cancel';
  value?: object;
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private logContent: LogContentService
  ) {}

  public async showEmailInput(title: string = 'Send kvittering på mail'): Promise<{ email: string | undefined }> {
    const dialog = this.dialogs.open<DialogCompletionData>(new PolymorpheusComponent(DialogBoxComponent, this.injector), {
      data: {
        title,
        inputPlaceholder: 'Indtast email',
        validation: 'email',
        action: 'Send',
        showInput: true,
      },
      size: 'auto',
    });

    const { role, value } = await lastValueFrom(
      dialog.pipe(untilDestroyed(this)).pipe(defaultIfEmpty({ role: 'cancel', value: undefined }))
    );

    if (role === 'confirm') {
      const emailRegexPattern: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      const email: string = value['input'];

      if (!email || !emailRegexPattern.test(email)) {
        this.logContent.logContent({
          level: ELogLevel.WARNING,
          description: 'Ugyldig email',
        });

        return { email: undefined };
      }

      return { email };
    }

    return { email: undefined };
  }

  public async showMessage(title: string, message: string, action: string = 'OK'): Promise<boolean> {
    const dialog = this.dialogs.open<DialogCompletionData>(new PolymorpheusComponent(DialogBoxComponent, this.injector), {
      data: {
        title,
        message,
        action,
      },
      size: 'auto',
    });

    const { role } = await lastValueFrom(dialog.pipe(untilDestroyed(this)).pipe(defaultIfEmpty({ role: 'cancel', value: undefined })));

    return role === 'confirm';
  }

  public async showPinCode(): Promise<boolean> {
    const dialog = this.dialogs.open<string | undefined>(new PolymorpheusComponent(PinCodeComponent, this.injector), { size: 'auto' });
    const data = await lastValueFrom(dialog.pipe(defaultIfEmpty(false)));

    return !!data;
  }

  public async showRefund(sale: ISaleModel, intents: IPaymentIntentModel[]): Promise<RefundIntents[]> {
    const dialog = this.dialogs.open<RefundIntents[] | undefined>(new PolymorpheusComponent(RefundDialogComponent, this.injector), {
      size: 'auto',
      data: { sale, intents },
    });

    return await lastValueFrom(dialog.pipe(defaultIfEmpty([])));
  }

  public async showConfirmMoveDialog(user: IUserModel, updatedEvent: ICalendarBookingModel): Promise<DialogCompletionData> {
    const dialog = this.dialogs.open<DialogCompletionData>(new PolymorpheusComponent(ConfirmMoveDialogComponent, this.injector), {
      size: 'auto',
      data: {
        user,
        updatedEvent,
      },
    });

    return await lastValueFrom(dialog.pipe(untilDestroyed(this)).pipe(defaultIfEmpty({ role: 'cancel', value: undefined })));
  }
}
