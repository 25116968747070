import { Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext } from '@taiga-ui/core';
import { EAvatarSize } from '@app/shared/components/user-avatar/user-avatar.component';
import { AsyncPipe, DatePipe } from '@angular/common';
import { FindUserModule } from '@app/shared/pipes/find-user/find-user.module';
import { UserAvatarModule } from '@app/shared/components/user-avatar/user-avatar.module';
import { ICalendarBookingModel } from '@models/calendar-booking.model';
import { IUserModel } from '@models/user.model';
import { Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { selectUsers } from '@core/store/authentication/authentication.selectors';
import { LocalDateModule } from '@app/shared/pipes/local-date/local-date.module';
import { DialogCompletionData } from '@services/dialog/dialog.service';

type ConfirmMoveDialogData = {
  user: IUserModel;
  updatedEvent: ICalendarBookingModel;
};

@Component({
  selector: 'app-confirm-move-dialog',
  standalone: true,
  imports: [AsyncPipe, FindUserModule, UserAvatarModule, DatePipe, LocalDateModule],
  templateUrl: './confirm-move-dialog.component.html',
})
export class ConfirmMoveDialogComponent {
  public readonly user: IUserModel;
  public readonly updatedEvent: ICalendarBookingModel;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<DialogCompletionData>,
    private readonly store: Store<IRootState>
  ) {
    if (!context.data) {
      throw new Error('No data provided');
    }

    const contextData: ConfirmMoveDialogData = context.data;
    this.user = contextData.user;
    this.updatedEvent = contextData.updatedEvent;
  }

  public cancel(): void {
    this.context.completeWith({ role: 'cancel' });
  }

  public confirm(): void {
    this.context.completeWith({ role: 'confirm' });
  }

  protected readonly EAvatarSize = EAvatarSize;
  protected readonly $users = this.store.select(selectUsers);
}
