export enum EPaymentMethodType {
  cash = 'cash',
  card = 'card',
  voucher = 'gift_card',
  flatpay = 'flatpay',
}

type PaymentMethodType = { type: string; text: string; icon: string };

type BlockingTypes = {
  [key in EPaymentMethodType]: PaymentMethodType;
};

export const paymentMethodTypes: BlockingTypes = {
  [EPaymentMethodType.cash]: {
    type: EPaymentMethodType.cash,
    text: 'Kontant',
    icon: 'assets/icon/cash.svg',
  },
  [EPaymentMethodType.card]: {
    type: EPaymentMethodType.card,
    text: 'Digital',
    icon: 'assets/icon/credit-card.svg',
  },
  [EPaymentMethodType.flatpay]: {
    type: EPaymentMethodType.flatpay,
    text: 'Flatpay',
    icon: 'assets/icon/credit-card.svg',
  },
  [EPaymentMethodType.voucher]: {
    type: EPaymentMethodType.voucher,
    text: 'Gavekort',
    icon: 'assets/icon/gift.svg',
  },
};
