import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';
import {Store} from '@ngrx/store';
import {LanguageService} from '@app/core/services/language/language.service';
import {IRootState} from "@app/core/store/root.state";
import moment from 'moment';

@Pipe({
	name: 'localDate',
})
export class LocalDatePipe implements PipeTransform {

	constructor(
		private store: Store<IRootState>,
		private languageService: LanguageService,
	) {
	}

	public transform(value: any, format?: string, utc?: boolean): any {
		if (!value) {
			return '';
		}

    if (typeof value === 'string') {
			value = value.replace(/ /g, 'T');
		}

    if (!format) {
      const inputDate = moment(value).startOf('day');
      const today = moment(new Date()).startOf('day');
      const diffDays = inputDate.diff(today, 'days');

      if (diffDays === 0) {
        return 'I dag';
      } else if (diffDays === 1) {
        return 'I morgen';
      } else if (diffDays === -1) {
        return 'I går';
      } else {
        return moment(value).format('dddd [d.] DD/MM');
      }
    }

		return formatDate(value, format, this.languageService.getCurrentLanguage().angularKey);
	}
}
