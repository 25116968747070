<div class="flex w-[480px] max-w-full flex-col gap-10 rounded-lg p-6">
  <div>
    <h2>Flyt booking?</h2>
    <p class="text-brand-gray-400">Bekræft at du vil flytte bookingen til en ny tid</p>
  </div>

  <div class="flex flex-col gap-2">
    <div class="flex items-center gap-2">
      <app-user-avatar [user]="user" [size]="EAvatarSize.SMALL"></app-user-avatar>
      <p>{{ user.name }}</p>
    </div>

    <div class="flex h-[68px] overflow-hidden rounded-lg" [style.background-color]="user.primary_color">
      <div class="h-full min-h-full min-w-[4px] max-w-[4px]" [style.background-color]="user.secondary_color"></div>
      <div class="flex w-full flex-col px-2">
        <div class="flex justify-between">
          <p>{{ updatedEvent.customer_name }}</p>
          <p class="text-sm">{{ updatedEvent.from_date | localDate: 'HH:mm' }} - {{ updatedEvent.to_date | localDate: 'HH:mm' }}</p>
        </div>

        <p class="text-sm">{{ updatedEvent.description }}</p>
      </div>
    </div>
    <p class="text-sm text-brand-gray-400">{{ updatedEvent.customer_name }} vil blive informeret om bookingændringen via e-mail</p>
  </div>

  <div class="flex items-center gap-2">
    <button class="option-button medium-btn w-[35%]" (click)="cancel()">Annuller</button>
    <button class="primary-button medium-btn w-full" (click)="confirm()">Flyt booking</button>
  </div>
</div>
