import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiDialogContext } from '@taiga-ui/core';
import { DialogCompletionData } from '@services/dialog/dialog.service';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogBoxComponent implements OnInit {
  public title: string | undefined;
  public message: string | undefined;
  public action: string = 'OK';

  public showInput = false;
  public inputPlaceholder = 'Indtast email';
  public validation: 'email' | 'required' | 'none';

  public inputText: string | undefined;

  public validationForm: FormGroup;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<DialogCompletionData>,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    const data: any = this.context.data;

    if (this.context && this.context.data && data) {
      if (data.title) {
        this.title = data.title;
      }

      if (data.message) {
        this.message = data.message;
      }

      if (data.action) {
        this.action = data.action;
      }

      if (data.showInput) {
        this.showInput = data.showInput;
      }

      if (data.inputPlaceholder) {
        this.inputPlaceholder = data.inputPlaceholder;
      }

      if (data.validation) {
        this.validation = data.validation;
      }
    }

    let validators = [];

    if (this.validation === 'email') {
      validators = [Validators.required, Validators.email];
    } else if (this.validation === 'required') {
      validators = [Validators.required];
    }

    this.validationForm = this.formBuilder.group({
      input: ['', validators],
    });
  }

  public submitForm(): void {
    if (this.validationForm.valid) {
      this.inputText = this.validationForm.get('input').value;
    }
  }

  public async cancel(): Promise<void> {
    this.context.completeWith({ role: 'cancel', value: undefined });
  }

  public async confirm(): Promise<void> {
    if (this.validationForm.valid) {
      this.inputText = this.validationForm.get('input').value;
    } else {
      return;
    }

    const data = this.showInput ? { input: this.inputText } : null;
    this.context.completeWith({ role: 'confirm', value: data });
  }
}
