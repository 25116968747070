<div class="flex flex-col gap-6 p-6 bg-brand-gray-50 rounded-2xl {{ showInput ? 'min-w-[500px]' : '' }}">
  <button class="absolute right-4 top-4 z-50 p-3" (click)="cancel()">
    <img src="assets/icon/x.svg" alt="icon" />
  </button>

  <div class="flex max-w-[430px] flex-col gap-2">
    <h2 *ngIf="title">{{ title }}</h2>
    <p *ngIf="message" class="text-brand-gray-400">{{ message }}</p>
  </div>

  <form *ngIf="showInput" [formGroup]="validationForm" (ngSubmit)="submitForm()">
    <input *ngIf="showInput" tuiAutoFocus formControlName="input" class="box-input w-full" [placeholder]="inputPlaceholder" />
    <div *ngIf="validationForm.get('input').invalid && (validationForm.get('input').dirty || validationForm.get('input').touched)">
      <p class="font-bold text-brand-error-500" *ngIf="validationForm.get('input').hasError('required')">Email er påkrævet</p>
      <p class="font-bold text-brand-error-500" *ngIf="validationForm.get('input').hasError('email')">Ugyldig email</p>
    </div>
  </form>

  <div class="flex w-full justify-between gap-4">
    <button class="tertiary-button medium-btn !aspect-auto !w-full" (click)="cancel()">Annuller</button>

    <button [disabled]="showInput && validationForm.invalid" class="secondary-button medium-btn !w-full" (click)="confirm()">
      {{ action }}
    </button>
  </div>
</div>
