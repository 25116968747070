import { Pipe, PipeTransform } from '@angular/core';
import {IUserModel} from "@models/user.model";

@Pipe({
  name: 'findUser'
})
export class FindUserPipe implements PipeTransform {

  public transform(user_id: string, users: IUserModel[]): IUserModel | undefined {
    return users.find(u => u.id === user_id);
  }

}
