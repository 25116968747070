import { Action } from '@ngrx/store';
import { IReportModel } from '@models/report.model';
import { PaginationHolder } from '@models/pagination.model';
import { IPaymentMethodModel } from '@models/payment-method.model';
import { ISaleModel } from '@models/sale.model';
import { IFlatpayIntegrationModel } from '@models/flatpay-integration.model';
import { IFlatpayTerminalModel } from '@models/flatpay-terminal.model';

export enum EEconomyActions {
  FetchReport = '[ECONOMY] Fetch Report',
  FetchReportSuccess = '[ECONOMY] Fetch Report Success',

  FetchSales = '[ECONOMY] Fetch Sales',
  FetchSalesSuccess = '[ECONOMY] Fetch Sales Success',

  FetchSaleById = '[ECONOMY] Fetch Sale By Id',
  FetchSaleByIdSuccess = '[ECONOMY] Fetch Sale By Id Success',

  FetchPaymentMethods = '[ECONOMY] Fetch Payment Methods',
  FetchPaymentMethodsSuccess = '[ECONOMY] Fetch Payment Methods Success',

  CreatePaymentMethod = '[ECONOMY] Create Payment Method',
  CreatePaymentMethodSuccess = '[ECONOMY] Create Payment Method Success',

  EditPaymentMethod = '[ECONOMY] Edit Payment Method',
  EditPaymentMethodSuccess = '[ECONOMY] Edit Payment Method Success',

  DeletePaymentMethod = '[ECONOMY] Delete Payment Method',
  DeletePaymentMethodSuccess = '[ECONOMY] Delete Payment Method Success',

  FetchFlatpay = '[ECONOMY] Fetch Flatpay',
  FetchFlatpaySuccess = '[ECONOMY] Fetch Flatpay Success',

  ActivateFlatpay = '[ECONOMY] Activate Flat Pay',
  ActivateFlatpaySuccess = '[ECONOMY] Activate Flat Pay Success',

  DeactivateFlatpay = '[ECONOMY] Deactivate Flat Pay',
  DeactivateFlatpaySuccess = '[ECONOMY] Deactivate Flat Pay Success',

  FetchFlatpayTerminals = '[ECONOMY] Fetch Flatpay Terminals',
  FetchFlatpayTerminalsSuccess = '[ECONOMY] Fetch Flatpay Terminals Success',

  CreateFlatpayTerminal = '[ECONOMY] Create Flatpay Terminal',
  CreateFlatpayTerminalSuccess = '[ECONOMY] Create Flatpay Terminal Success',

  DeleteFlatpayTerminal = '[ECONOMY] Delete Flatpay Terminal',
  DeleteFlatpayTerminalSuccess = '[ECONOMY] Delete Flatpay Terminal Success',
}

// Fetch Report

export class FetchReport implements Action {
  public readonly type = EEconomyActions.FetchReport;

  constructor(
    public from_date: string,
    public to_date: string
  ) {}
}

export class FetchReportSuccess implements Action {
  public readonly type = EEconomyActions.FetchReportSuccess;

  constructor(public report: IReportModel) {}
}

// Fetch Sales

export class FetchSales implements Action {
  public readonly type = EEconomyActions.FetchSales;

  constructor(
    public from_date: string,
    public to_date: string,
    public next_page?: number
  ) {}
}

export class FetchSalesSuccess implements Action {
  public readonly type = EEconomyActions.FetchSalesSuccess;

  constructor(public sales: PaginationHolder<ISaleModel>) {}
}

// Fetch Sale By Id

export class FetchSaleById implements Action {
  public readonly type = EEconomyActions.FetchSaleById;

  constructor(public id: string) {}
}

export class FetchSaleByIdSuccess implements Action {
  public readonly type = EEconomyActions.FetchSaleByIdSuccess;

  constructor(public sale: ISaleModel) {}
}

// Fetch Payment Methods

export class FetchPaymentMethods implements Action {
  public readonly type = EEconomyActions.FetchPaymentMethods;

  constructor() {}
}

export class FetchPaymentMethodsSuccess implements Action {
  public readonly type = EEconomyActions.FetchPaymentMethodsSuccess;

  constructor(public paymentMethods: PaginationHolder<IPaymentMethodModel>) {}
}

// Create Payment Method

export class CreatePaymentMethod implements Action {
  public readonly type = EEconomyActions.CreatePaymentMethod;

  constructor(
    public name: string,
    public paymentType: string,
    public flatpayToken?: IFlatpayIntegrationModel['access_token']
  ) {}
}

export class CreatePaymentMethodSuccess implements Action {
  public readonly type = EEconomyActions.CreatePaymentMethodSuccess;

  constructor(public paymentType: string) {}
}

// Create Payment Method

export class EditPaymentMethod implements Action {
  public readonly type = EEconomyActions.EditPaymentMethod;

  constructor(
    public id: string,
    public name: string,
    public paymentType: string,
    public flatpayToken?: IFlatpayIntegrationModel['access_token']
  ) {}
}

export class EditPaymentMethodSuccess implements Action {
  public readonly type = EEconomyActions.EditPaymentMethodSuccess;

  constructor() {}
}

// Delete Payment Method

export class DeletePaymentMethod implements Action {
  public readonly type = EEconomyActions.DeletePaymentMethod;

  constructor(
    public id: string,
    public paymentType: string
  ) {}
}

export class DeletePaymentMethodSuccess implements Action {
  public readonly type = EEconomyActions.DeletePaymentMethodSuccess;

  constructor() {}
}

// Fetch Flatpay

export class FetchFlatpay implements Action {
  public readonly type = EEconomyActions.FetchFlatpay;

  constructor() {}
}

export class FetchFlatpaySuccess implements Action {
  public readonly type = EEconomyActions.FetchFlatpaySuccess;

  constructor(public flatpay: IFlatpayIntegrationModel) {}
}

// Activate Flatpay

export class ActivateFlatpay implements Action {
  public readonly type = EEconomyActions.ActivateFlatpay;

  constructor(public token: IFlatpayIntegrationModel['access_token']) {}
}

export class ActivateFlatpaySuccess implements Action {
  public readonly type = EEconomyActions.ActivateFlatpaySuccess;

  constructor() {}
}

// Deactivate Flatpay

export class DeactivateFlatpay implements Action {
  public readonly type = EEconomyActions.DeactivateFlatpay;

  constructor() {}
}

export class DeactivateFlatpaySuccess implements Action {
  public readonly type = EEconomyActions.DeactivateFlatpaySuccess;

  constructor() {}
}

// Fetch Flatpay Terminals

export class FetchFlatpayTerminals implements Action {
  public readonly type = EEconomyActions.FetchFlatpayTerminals;

  constructor() {}
}

export class FetchFlatpayTerminalsSuccess implements Action {
  public readonly type = EEconomyActions.FetchFlatpayTerminalsSuccess;

  constructor(public terminals: PaginationHolder<IFlatpayTerminalModel>) {}
}

// Create Flatpay Terminal

export class CreateFlatpayTerminal implements Action {
  public readonly type = EEconomyActions.CreateFlatpayTerminal;

  constructor(
    public name: IFlatpayTerminalModel['name'],
    public externalId: IFlatpayTerminalModel['external_id'],
    public shouldPrint: boolean
  ) {}
}

export class CreateFlatpayTerminalSuccess implements Action {
  public readonly type = EEconomyActions.CreateFlatpayTerminalSuccess;

  constructor() {}
}

// Delete Flatpay Terminal

export class DeleteFlatpayTerminal implements Action {
  public readonly type = EEconomyActions.DeleteFlatpayTerminal;

  constructor(public id: IFlatpayTerminalModel['id']) {}
}

export class DeleteFlatpayTerminalSuccess implements Action {
  public readonly type = EEconomyActions.DeleteFlatpayTerminalSuccess;

  constructor() {}
}

export type EconomyActions =
  | FetchReport
  | FetchReportSuccess
  | FetchSales
  | FetchSalesSuccess
  | FetchSaleById
  | FetchSaleByIdSuccess
  | FetchPaymentMethods
  | FetchPaymentMethodsSuccess
  | CreatePaymentMethod
  | CreatePaymentMethodSuccess
  | EditPaymentMethod
  | EditPaymentMethodSuccess
  | DeletePaymentMethod
  | DeletePaymentMethodSuccess
  | FetchFlatpay
  | FetchFlatpaySuccess
  | ActivateFlatpay
  | ActivateFlatpaySuccess
  | DeactivateFlatpay
  | DeactivateFlatpaySuccess
  | FetchFlatpayTerminals
  | FetchFlatpayTerminalsSuccess
  | CreateFlatpayTerminal
  | CreateFlatpayTerminalSuccess
  | DeleteFlatpayTerminal
  | DeleteFlatpayTerminalSuccess;
