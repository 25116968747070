import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input() public placeholder = 'Søg...';
  @Input() public input = '';
  @Output() public inputChange = new EventEmitter<string>();

  constructor(
    private _translate: TranslateService,
  ) { }

  public ngOnInit(): void {

  }

}
