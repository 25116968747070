import { Pipe, PipeTransform } from '@angular/core';
import {ICustomerModel} from "@models/customer.model";

@Pipe({
  name: 'customerFilter'
})
export class CustomerFilterPipe implements PipeTransform {
  public transform(value: ICustomerModel[], search: string): ICustomerModel[] {
    if (!value) {
      return [];
    }

    return value.filter(c => c.name.toLowerCase().includes(search.toLowerCase()));
  }
}
