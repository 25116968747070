<div class="flex min-w-[520px] flex-col gap-6 p-6">
  <h1 class="font-bold">Refunder betaling</h1>

  <!-- Sale data -->
  <div class="flex flex-col gap-4">
    <!-- Sale Lines -->
    <div class="flex flex-col gap-2">
      <!-- Line -->
      <div
        *ngFor="let line of sale?.lines"
        (click)="didClickLine(line)"
        class="flex justify-between p-2 bg-brand-gray-50 gap-4 rounded-lg cursor-pointer {{
          selectedLines.includes(line) ? 'bg-brand-lilac-600 text-white' : 'hover:bg-brand-lilac-200'
        }}">
        <div class="pointer-events-none flex gap-2">
          <input
            [checked]="selectedLines.includes(line)"
            type="checkbox"
            class="h-6 w-6 rounded border-brand-gray-200 bg-brand-gray-200 text-brand-dark ring-0 focus:outline-none" />
          <p class="font-medium">{{ line.description }}</p>
        </div>

        <p class="font-bold">{{ line.total_amount | localCurrency }}</p>
      </div>
    </div>

    <!-- Total -->
    <div class="flex w-full flex-col">
      <div class="flex items-center justify-between gap-4" *ngIf="totalRefunded > 0">
        <p>Refunderet</p>
        <p class="font-bold">-{{ totalRefunded | localCurrency }}</p>
      </div>
      <div class="flex items-center justify-between gap-4">
        <p>Total</p>
        <h1 class="font-bold">{{ sale?.total_amount | localCurrency }}</h1>
      </div>
    </div>
  </div>

  <!-- Refund field -->
  <div class="flex flex-col gap-2">
    <p class="text-sm font-medium text-brand-gray-600">Refundering</p>

    <form *ngIf="validationForm" [formGroup]="validationForm">
      <div class="relative flex">
        <input [value]="refund" disableNumberScroll type="number" formControlName="input" placeholder="Indtast beløb" class="box-input" />
        <div class="absolute right-2 top-1/2 flex -translate-y-1/2 items-center justify-center rounded-full bg-brand-gray-200 px-2 py-1">
          <p class="text-sm font-medium">DKK</p>
        </div>
      </div>
      <div *ngIf="validationForm.get('input')?.invalid && (validationForm.get('input')?.dirty || validationForm.get('input')?.touched)">
        <p class="font-medium text-brand-error-500" *ngIf="validationForm.get('input')?.hasError('required')">Beløb er påkrævet</p>
        <p class="font-medium text-brand-error-500" *ngIf="validationForm.get('input')?.hasError('max')">
          Beløbet er større end det resterende beløb
        </p>
        <p class="font-medium text-brand-error-500" *ngIf="validationForm.get('input')?.hasError('min')">
          Beløbet må ikke være mindre end 1 kr.
        </p>
      </div>
    </form>
  </div>

  <button class="secondary-button medium-btn mt-4" (click)="didClickRefund()">Refunder</button>
</div>
