import { Action } from '@ngrx/store';
import { IUserModel } from '@models/user.model';
import { ICreateBookingRequestModel } from '@models/request/create-booking.request.model';
import { ICalendarModel } from '@models/calendar.model';
import { IUpdateBookingRequestModel } from '@models/request/update-booking.request.model';
import { ICalendarBookingModel } from '@models/calendar-booking.model';
import { IBookingSettingsModel, IUpdateBookingSettingsRequestModel } from '@models/booking-settings.model';
import { PaginationHolder } from '@models/pagination.model';
import { ICreateBlockingRequestModel } from '@models/request/create-blocking.request.model';
import { IUpdateBlockingRequestModel } from '@models/request/update-blocking.request.model';
import { IBookingOverlapModel } from '@models/booking-overlap.model';
import { IWaitlistModel } from '@models/waitlist.model';

export enum EBookingActions {
  FetchBookingSettings = '[BOOKING] Fetch Booking Settings',
  FetchBookingSettingsSuccess = '[BOOKING] Fetch Booking Settings Success',

  UpdateBookingSettings = '[BOOKING] Update Booking Settings',
  UpdateBookingSettingsSuccess = '[BOOKING] Update Booking Settings Success',

  CreateBooking = '[BOOKING] Create Booking',
  CreateBookingSuccess = '[BOOKING] Create Booking Success',

  UpdateBooking = '[BOOKING] Update Booking',
  UpdateBookingSuccess = '[BOOKING] Update Booking Success',

  CheckOverlap = '[BOOKING] Check Overlap',
  CheckOverlapSuccess = '[BOOKING] Check Overlap Success',
  ClearOverlap = '[BOOKING] Clear Overlap',

  DeleteBooking = '[BOOKING] Delete Booking',
  DeleteBookingSuccess = '[BOOKING] Delete Booking Success',

  CreateBlocking = '[BOOKING] Create Blocking',
  CreateBlockingSuccess = '[BOOKING] Create Blocking Success',

  UpdateBlocking = '[BOOKING] Update Blocking',
  UpdateBlockingSuccess = '[BOOKING] Update Blocking Success',

  DeleteBlocking = '[BOOKING] Delete Blocking',
  DeleteBlockingSuccess = '[BOOKING] Delete Blocking Success',

  FetchCalendarBookings = '[BOOKING] Fetch Calendar Bookings',
  FetchCalendarBookingsSuccess = '[BOOKING] Fetch Calendar Bookings Success',

  FetchBookingById = '[BOOKING] Fetch Booking By Id',
  FetchBookingByIdSuccess = '[BOOKING] Fetch Booking By Id Success',

  FetchCustomerBookings = '[BOOKING] Fetch Customer Bookings',
  FetchCustomerBookingsSuccess = '[BOOKING] Fetch Customer Bookings Success',

  SetCurrentDate = '[BOOKING] Set Current Date',
  SetIsWeekView = '[BOOKING] Set Is Week View',
  SetCalendarUser = '[BOOKING] Set Calendar User',

  FetchWaitlist = '[BOOKING] Fetch Waitlist',
  FetchWaitlistSuccess = '[BOOKING] Fetch Waitlist Success',
}

// Fetch Booking Settings

export class FetchBookingSettings implements Action {
  public readonly type = EBookingActions.FetchBookingSettings;

  constructor() {}
}

export class FetchBookingSettingsSuccess implements Action {
  public readonly type = EBookingActions.FetchBookingSettingsSuccess;

  constructor(public bookingSettings: IBookingSettingsModel) {}
}

// Update Booking Settings

export class UpdateBookingSettings implements Action {
  public readonly type = EBookingActions.UpdateBookingSettings;

  constructor(public reqModel: IUpdateBookingSettingsRequestModel) {}
}

export class UpdateBookingSettingsSuccess implements Action {
  public readonly type = EBookingActions.UpdateBookingSettingsSuccess;

  constructor(public bookingSettings: IBookingSettingsModel) {}
}

// Create Booking

export class CreateBooking implements Action {
  public readonly type = EBookingActions.CreateBooking;

  constructor(public reqModel: ICreateBookingRequestModel) {}
}

export class CreateBookingSuccess implements Action {
  public readonly type = EBookingActions.CreateBookingSuccess;

  constructor(public account: IUserModel) {}
}

// Update Booking

export class UpdateBooking implements Action {
  public readonly type = EBookingActions.UpdateBooking;

  constructor(
    public booking_id: string,
    public reqModel: IUpdateBookingRequestModel
  ) {}
}

export class UpdateBookingSuccess implements Action {
  public readonly type = EBookingActions.UpdateBookingSuccess;

  constructor() {}
}

// Check Overlap

export class CheckOverlap implements Action {
  public readonly type = EBookingActions.CheckOverlap;

  constructor(
    public from_date: string,
    public to_date: string,
    public user_id: string,
    public booking_id?: string,
    public time_block_id?: string
  ) {}
}

export class CheckOverlapSuccess implements Action {
  public readonly type = EBookingActions.CheckOverlapSuccess;

  constructor(public overlap: IBookingOverlapModel) {}
}

export class ClearOverlap implements Action {
  public readonly type = EBookingActions.ClearOverlap;

  constructor() {}
}

// Delete Booking

export class DeleteBooking implements Action {
  public readonly type = EBookingActions.DeleteBooking;

  constructor(public booking: ICalendarBookingModel) {}
}

export class DeleteBookingSuccess implements Action {
  public readonly type = EBookingActions.DeleteBookingSuccess;

  constructor() {}
}

// Create Blocking

export class CreateBlocking implements Action {
  public readonly type = EBookingActions.CreateBlocking;

  constructor(public reqData: ICreateBlockingRequestModel) {}
}

export class CreateBlockingSuccess implements Action {
  public readonly type = EBookingActions.CreateBlockingSuccess;

  constructor() {}
}

// Update Blocking

export class UpdateBlocking implements Action {
  public readonly type = EBookingActions.UpdateBlocking;

  constructor(
    public id: string,
    public reqData: IUpdateBlockingRequestModel
  ) {}
}

export class UpdateBlockingSuccess implements Action {
  public readonly type = EBookingActions.UpdateBlockingSuccess;

  constructor() {}
}

// Delete Blocking

export class DeleteBlocking implements Action {
  public readonly type = EBookingActions.DeleteBlocking;

  constructor(public id: string) {}
}

export class DeleteBlockingSuccess implements Action {
  public readonly type = EBookingActions.DeleteBlockingSuccess;

  constructor() {}
}

// Fetch Calendar Bookings

export class FetchCalendarBookings implements Action {
  public readonly type = EBookingActions.FetchCalendarBookings;

  constructor() {}
}

export class FetchCalendarBookingsSuccess implements Action {
  public readonly type = EBookingActions.FetchCalendarBookingsSuccess;

  constructor(public calendar: ICalendarModel[]) {}
}

// Fetch Booking By Id

export class FetchBookingById implements Action {
  public readonly type = EBookingActions.FetchBookingById;

  constructor(public id: string) {}
}

export class FetchBookingByIdSuccess implements Action {
  public readonly type = EBookingActions.FetchBookingByIdSuccess;

  constructor(public booking: ICalendarBookingModel) {}
}

// Fetch Customer Bookings

export class FetchCustomerBookings implements Action {
  public readonly type = EBookingActions.FetchCustomerBookings;

  constructor(public id: string) {}
}

export class FetchCustomerBookingsSuccess implements Action {
  public readonly type = EBookingActions.FetchCustomerBookingsSuccess;

  constructor(public bookings: PaginationHolder<ICalendarBookingModel>) {}
}

// Set Current Date

export class SetCurrentDate implements Action {
  public readonly type = EBookingActions.SetCurrentDate;

  constructor(public date: Date) {}
}

// Set Is Week View

export class SetIsWeekView implements Action {
  public readonly type = EBookingActions.SetIsWeekView;

  constructor(public isWeekView: boolean) {}
}

// Set Calendar User

export class SetCalendarUser implements Action {
  public readonly type = EBookingActions.SetCalendarUser;

  constructor(public user: IUserModel) {}
}

// Fetch Waitlist

export class FetchWaitlist implements Action {
  public readonly type = EBookingActions.FetchWaitlist;

  constructor() {}
}

export class FetchWaitlistSuccess implements Action {
  public readonly type = EBookingActions.FetchWaitlistSuccess;

  constructor(public data: PaginationHolder<IWaitlistModel>) {}
}

// Types

export type BookingActions =
  | FetchBookingSettings
  | FetchBookingSettingsSuccess
  | UpdateBookingSettings
  | UpdateBookingSettingsSuccess
  | CreateBooking
  | CreateBookingSuccess
  | UpdateBooking
  | UpdateBookingSuccess
  | CheckOverlap
  | CheckOverlapSuccess
  | ClearOverlap
  | DeleteBooking
  | DeleteBookingSuccess
  | CreateBlocking
  | CreateBlockingSuccess
  | UpdateBlocking
  | UpdateBlockingSuccess
  | DeleteBlocking
  | DeleteBlockingSuccess
  | FetchCalendarBookings
  | FetchCalendarBookingsSuccess
  | FetchCustomerBookings
  | FetchCustomerBookingsSuccess
  | FetchBookingById
  | FetchBookingByIdSuccess
  | SetCurrentDate
  | SetIsWeekView
  | SetCalendarUser
  | FetchWaitlist
  | FetchWaitlistSuccess;
